import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NbThemeModule,
  NbIconModule,
  NbMenuModule,
  NbToastrModule,
  NbCardModule,
  NbDialogModule,
  NbButtonModule,
  NbIconLibraries,
  NbDatepickerModule,
  NbAutocompleteModule,
  NbWindowModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { ToastService } from './services/toast.service';
import { ApiService } from './services/api.service';
import { DialogComponent } from './shared/dialog/dialog.component';
import { DialogService } from './services/dialog.service';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './services/loader.service';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { RoleProvider } from './providers/role.provider';
import { ACL } from './providers/acl.list';
import { DEFAULT_THEME } from 'src/@theme/theme.default';
import { COSMIC_THEME } from 'src/@theme/theme.cosmic';
import { CORPORATE_THEME } from 'src/@theme/theme.corporate';
import { DARK_THEME } from 'src/@theme/theme.dark';
import { AuthModule } from './auth/auth.module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TokenInterceptor } from './providers/token.interceptor';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { VimeModule } from '@vime/angular';
import { ChartsModule } from 'ng2-charts';
// import { DetailsComponent } from './modules/subadmin/details/details.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';

// import { SocketService } from './services/socket.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { DetailsComponent } from './modules/details/details.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const maskConfig: Partial<IConfig> = {
  validation: true,
};

const NB_MODULES = [
  NbThemeModule.forRoot({ name: 'corporate' }, [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]),
  NbIconModule,
  NbEvaIconsModule,
  NbMenuModule.forRoot(),
  NbToastrModule.forRoot(),
  NbCardModule,
  NbDialogModule.forRoot(),
  NbButtonModule,
  NbDatepickerModule.forRoot(),
  NbAutocompleteModule,
  NbWindowModule.forRoot(),

  NbSecurityModule.forRoot({
    accessControl: { ...ACL },
  }),
];
const COMMON_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  HttpClientModule,
  AuthModule.forRoot(),
  NgxMaskModule.forRoot(maskConfig),
  NgxDropzoneModule,
  VimeModule,
  ChartsModule,
  FormsModule,
  ReactiveFormsModule,
  SelectDropDownModule,
  NgMultiSelectDropDownModule.forRoot(),
];
const COMPONENTS = [AppComponent, DialogComponent, LoaderComponent];

const PROVIDERS = [
  Title,
  ApiService,
  AuthService,
  ToastService,
  DialogService,
  LoaderService,
  // SocketService,

  { provide: NbRoleProvider, useClass: RoleProvider },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...NB_MODULES, ...COMMON_MODULES, VimeModule, NgbModule],
  providers: [...PROVIDERS],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerFontPack('font-awesome-solid', { packClass: 'fas', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('font-awesome-regular', { packClass: 'far', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('font-awesome-brands', { packClass: 'fab', iconClassPrefix: 'fa' });
    this.iconLibraries.registerSvgPack('custom', {
      'custom-icon': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 280" width="40" height="50" fill="currentcolor">
  <g>
    <path d="M54.997,48.997H4.999c-1.657,0-3-1.343-3-3s1.343-3,3-3h49.999c1.657,0,3,1.343,3,3S56.654,48.997,54.997,48.997z"/>
  </g>
  <g>
    <path d="M34.998,68.997h-50c-1.657,0-3-1.344-3-3s1.343-3,3-3h50c1.656,0,3,1.343,3,3S36.654,68.997,34.998,68.997z"/>
  </g>
  <g>
    <path d="M54.997,88.999H4.999c-1.657,0-3-1.344-3-3c0-1.658,1.343-3,3-3h49.999c1.657,0,3,1.342,3,3   C57.997,87.655,56.654,88.999,54.997,88.999z"/>
  </g>
  <path d="M184.852,59.808l-25.184-7.474c-3.697-3.103-6.442-5.738-8.664-7.876c-9.16-8.814-12.208-10.895-35.521-10.895  c-22.918,0-50.043,11.615-50.043,21.427l0.001,15.435c0,3.972,3.22,7.192,7.192,7.192h8.832c0.416,6.353,5.687,11.382,12.146,11.382  s11.73-5.029,12.147-11.382h47.03c0.416,6.353,5.688,11.382,12.146,11.382s11.729-5.029,12.146-11.382h6.23  c3.972,0,7.192,0.376,7.192-3.598V66.83C190.501,63.388,189.919,61.377,184.852,59.808z M77.865,48.668  c1.704-0.998,3.738-2.041,6.141-3.081c4.974-2.154,10.403-3.835,15.897-4.967l0.897,9.057L77.865,48.668z M106.244,49.917  L105.232,39.7c3.477-0.486,6.926-0.743,10.25-0.743c22.025,0,23.897,1.803,31.779,9.387c1.104,1.062,2.331,2.243,3.737,3.542  L106.244,49.917z"/>
</svg>

`,
    });
  }
}
