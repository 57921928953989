import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service'
import { ToastService } from '../../services/toast.service'

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  submitted: boolean = true;
  user: any = { email: '' };
  body: any = {}
  session_id: string = '';
  otp: string = "";
  constructor(
    protected router: Router,
    private _api: ApiService,
    private _route: ActivatedRoute,
    private _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(async params => {
      if (params['session_id']) this.session_id = params['session_id']
    });
  }

  async otpVerification() {
    this.submitted = true;
    const formData = { session_id: this.session_id, otp: this.otp };
    const { data, error, message } = await this._api.post('auth/otp/verify', formData);
    if (!!error) {
      this.submitted = false;
      this._toast.error(message);
      return;
    }
    this._toast.success(message)
    this.router.navigate([`auth/reset-password/${data?.session_id}`])
  }

  onOtpChange(otp: any) {
    this.otp = otp
    if (this.otp.length === 6) this.submitted = false;
  }

  async resendOTP() {
    this.submitted = true;
    const formData = { session_id: this.session_id };
    const { data, error, message } = await this._api.post('auth/otp/send', formData);
    if (!!error) {
      this.submitted = false;
      this._toast.error(message);
      return;
    }
    if (data?.session_id) this._toast.success('Code sent successfully');
  }
}
