import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next)).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    )
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const urls: string[] = ['/auth/logout'];
    if (urls.indexOf(req.url.replace(environment.API_ENDPOINT, "")) > -1) {
      const authService = this.injector.get(AuthService); // get it here within intercept
      const isAuth = await authService.isAuthenticated()
      if (!!isAuth) {
        const token = await authService.getToken();
        req.headers.set('Authorization', `Bearer ${token.getValue()}`);
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token.getValue()}`,
          },
        });
      }
    }
    return next.handle(req).toPromise();
  }

}
