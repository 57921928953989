// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  PROJECT_NAME: 'Town Connect',
  APP_NAME: 'Town Connect-admin',
  STRATEGY_NAME: 'email',
  VERSION: require('../../package.json').version,
  // API_ENDPOINT: 'http://localhost:3000',
  API_ENDPOINT: 'https://api-dev.townconnectnetwork.com',

  TOKEN_EXPIRE_IN: 900, //seconds
  SOCKET_ENDPOINT: 'https://mediator-dev.townconnectnetwork.com',
  HASH_KEY: 'showcase-admin-app',
  DATE_FORMAT: 'MM/dd/yyyy',
  DATE_TIME_FORMAT: 'MM/dd/yyyy hh:mm a',
  PAGINATION_LIMIT: 25,
  S3_BUCKET_NAME: 'fundyapp',
  S3_USERNAME: '',
  S3_PASSWORD: '',
  S3_ACCESS_KEY_ID: '',
  S3_SECRET_ACCESS_KEY_ID: '',
  S3_REGION: 'us-east-1',
  S3_FULL_URL: '',
  S3_MEDIA_FULL_URL: '',
  S3_TOWN_CONNECT:'https://townconnectdev.s3.amazonaws.com/',
  PHONE_MASK: '(000) 000-0000',
  GOOGLE_MAP_KEY: 'AIzaSyBn0hQZyPbyOY-XjdvcUd4AOQi8g9oAiOE',

  //  GOOGLE_MAP_KEY:'AIzaSyByaco5n5Oa3DZvt4138DjrDXBhUL7rzDQ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
