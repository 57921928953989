import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getDeepFromObject, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Auth } from 'src/app/models/Auth';
import { AuthService } from 'src/app/auth/auth.service';
import { deepCopy } from 'src/app/helpers/util.function';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  redirectDelay: number = 1000;
  showMessages: any = {};
  strategy: string = '';
  errors: string[] = [];
  messages: string[] = [];
  auth: Auth = {
    username: '',
    password: '',
    info: {},
    rememberMe: false
  };
  submitted: boolean = false;
  rememberMeAuth: any;
  constructor(
    protected authService: AuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router
  ) {
    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.strategy = this.getConfigValue('forms.login.strategy');
  }

  ngOnInit(): void {
    this.rememberMeAuth = this.authService.getCredential();
    if(this.rememberMeAuth.rememberMe){
      this.auth = {
        username: this.rememberMeAuth.username,
        password: this.rememberMeAuth.password,
        info: this.rememberMeAuth.info,
        rememberMe: this.rememberMeAuth.rememberMe,
      }
    } else {
      this.auth = {
        username: '',
        password:'',
        info: '',
        rememberMe: false,
      }
    }

  }

  async login(): Promise<void> {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    const result = await this.authService.authenticate(this.strategy, deepCopy(this.auth));
    this.submitted = false;
    if (result.isSuccess()) {
      this.messages = result.getMessages();

      const response: HttpResponse<any> = result?.getResponse();
      this.auth.info = {
        token: response.body.data.token,
        refresh_token: response.body.data.refresh_token,
        token_expiry: response.body.data.token_expiry,
        user: response.body.data.user
      }
      console.log(this.auth.info.user)
      await this.authService.saveCredential(this.auth);
      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
      this.cd.detectChanges();
    } else {
      console.log(result?.getResponse().error.message);

      this.errors = result?.getResponse().error.message;
    }
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
