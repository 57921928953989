import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { HttpService } from './http.service';
import {
  ApiResponse,
  ApiGetAllResponse,
  ApiGetOneResponse,
  ApiGetCountResponse,
  ApiUploadResponse,
} from '../models/ApiResponse';
import { QueryParams } from '../models/QueryParams';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiBaseUrl = environment.API_ENDPOINT;

  constructor(private httpService: HttpService, private authService: AuthService) {}

  generateQueryUrl(path: string, options?: QueryParams) {
    let url = `${this.apiBaseUrl}/${path}?`;
    if (typeof options === 'undefined') {
      return url;
    }
    if (!isNaN(Number(options.limit))) {
      url += 'limit=' + options.limit + '&';
    }
    if (!isNaN(Number(options.offset))) {
      url += 'offset=' + options.offset + '&';
    }
    if (options.search) {
      url += 'search=' + encodeURIComponent(options.search) + '&';
    }
    if (options.sort && Array.isArray(options.sort)) {
      url += 'sort=' + JSON.stringify(options.sort) + '&';
    }
    if (options.where && typeof options.where === 'object') {
      url += 'where=' + JSON.stringify(options.where) + '&';
    }
    if (options.filters && typeof options.filters === 'object') {
      url += 'filters=' + JSON.stringify(options.filters) + '&';
    }
    if (options.select && Array.isArray(options.select)) {
      url += 'select=' + options.select.join(',') + '&';
    }
    if (options.populate && Array.isArray(options.populate)) {
      url += 'populate=' + JSON.stringify(options.populate) + '&';
    }
    if (options.data && typeof options.data === 'object') {
      for (const key in options.data) {
        if (options.data.hasOwnProperty(key)) {
          url += `${key}=${options.data[key]}&`;
        }
      }
    }
    return url;
  }

  async getHttpOption(auth: boolean = true, additionalHeaders?: any) {
    const headers: any = {
      'Content-Type': 'application/json',
      ...additionalHeaders,
    };
    if (!!auth) {
      const token = await this.authService.getToken();
      if (!!token.isValid()) {
        headers.Authorization = `Bearer ${token.getValue()}`;
      }
    }
    return {
      headers: new HttpHeaders(headers),
    };
  }
  async getHttpOptionFormData(auth: boolean = true, additionalHeaders?: any) {
    const headers: any = {
      'Content-Type': 'multipart/form-data',
      ...additionalHeaders,
    };
    if (!!auth) {
      const token = await this.authService.getToken();
      if (!!token.isValid()) {
        headers.Authorization = `Bearer ${token.getValue()}`;
      }
    }
    return {
      headers: new HttpHeaders(headers),
    };
  }

  async get(url: string, options?: QueryParams, auth: boolean = true): Promise<ApiResponse> {
    try {
      const response = await this.httpService.get(this.generateQueryUrl(url, options), await this.getHttpOption(auth));
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async post(url: string, body?: any, options?: QueryParams, auth: boolean = true): Promise<ApiResponse> {
    try {
      const response = await this.httpService.post(
        this.generateQueryUrl(url, options),
        body,
        await this.getHttpOption(auth)
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async postFormData(url: string, body?: any, options?: QueryParams, auth: boolean = true): Promise<ApiResponse> {
    try {
      const headers: any = {};
      const token = await this.authService.getToken();
      if (!!token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await this.httpService.post(this.generateQueryUrl(url, options), body, {
        headers: new HttpHeaders(headers),
      });
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async put(url: string, body?: any, options?: QueryParams, auth: boolean = true): Promise<ApiResponse> {
    try {
      const response = await this.httpService.put(
        this.generateQueryUrl(url, options),
        body,
        await this.getHttpOption(auth)
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async putFormData(
    url: string,
    body?: any,
    id?: number | string,
    options?: QueryParams,
    auth: boolean = true
  ): Promise<ApiResponse> {
    try {
      const headers: any = {};
      const token = await this.authService.getToken();
      if (!!token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await this.httpService.put(this.generateQueryUrl(url, options), body, {
        headers: new HttpHeaders(headers),
      });
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async delete(url: string, options?: QueryParams, auth: boolean = true): Promise<ApiResponse> {
    try {
      const response = await this.httpService.delete(
        this.generateQueryUrl(url, options),
        await this.getHttpOption(auth)
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async patch(url: string, body?: any, options?: QueryParams, auth: boolean = true): Promise<ApiResponse> {
    try {
      const response = await this.httpService.patch(
        this.generateQueryUrl(url, options),
        body,
        await this.getHttpOption(auth)
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async me(options?: QueryParams): Promise<ApiGetOneResponse> {
    try {
      const response = await this.httpService.get(
        this.generateQueryUrl(`user/me`, options),
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async updateMe(body?: any, options?: QueryParams): Promise<ApiResponse> {
    try {
      const response = await this.httpService.put(
        this.generateQueryUrl(`user/me`, options),
        body,
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }
  async updateProfile(body?: any, options?: QueryParams): Promise<ApiResponse> {
    try {
      const headers: any = {};
      const token = await this.authService.getToken();
      if (!!token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await this.httpService.put(this.generateQueryUrl('user/image', options), body, {
        headers: new HttpHeaders(headers),
      });
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async postMultipartFormdata(url: string, body?: any, options?: QueryParams): Promise<ApiResponse> {
    try {
      const headers: any = {};
      const token = await this.authService.getToken();
      if (!!token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await this.httpService.post(this.generateQueryUrl(url, options), body, {
        headers: new HttpHeaders(headers),
      });
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async updateMultipartFormdata(
    entity: string,
    id: number | string,
    body?: any,
    options?: QueryParams
  ): Promise<ApiResponse> {
    try {
      const headers: any = {};
      const token = await this.authService.getToken();
      if (!!token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await this.httpService.put(this.generateQueryUrl(`${entity}/${id}`, options), body, {
        headers: new HttpHeaders(headers),
      });
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async getAll(entity: string, options?: QueryParams): Promise<ApiGetAllResponse> {
    try {
      const response = await this.httpService.get(this.generateQueryUrl(entity, options), await this.getHttpOption());
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async getById(entity: string, id: number | string, options?: QueryParams): Promise<ApiGetOneResponse> {
    try {
      const response = await this.httpService.get(
        this.generateQueryUrl(`${entity}/${id}`, options),
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async find(entity: string, options?: QueryParams): Promise<ApiGetOneResponse> {
    try {
      const response = await this.httpService.get(
        this.generateQueryUrl(`${entity}/find`, options),
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async getCount(entity: string, options?: QueryParams): Promise<ApiGetCountResponse> {
    try {
      const response = await this.httpService.get(
        this.generateQueryUrl(`${entity}/count`, options),
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async create(entity: string, body?: any, options?: QueryParams): Promise<ApiGetOneResponse> {
    try {
      const response = await this.httpService.post(
        this.generateQueryUrl(entity, options),
        body,
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async updateById(entity: string, id: number | string, body?: any, options?: QueryParams): Promise<ApiGetOneResponse> {
    try {
      const response = await this.httpService.put(
        this.generateQueryUrl(`${entity}/${id}`, options),
        body,
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  async deleteById(entity: string, id: number | string, options?: QueryParams): Promise<ApiGetOneResponse> {
    try {
      const response = await this.httpService.delete(
        this.generateQueryUrl(`${entity}/${id}`, options),
        await this.getHttpOption()
      );
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }
  async upload(url: string, body?: FormData, options?: QueryParams): Promise<ApiUploadResponse> {
    try {
      const headers: any = {};
      const token = await this.authService.getToken();
      if (!!token) {
        headers.Authorization = `Bearer ${token}`;
      }
      const response = await this.httpService.post(this.generateQueryUrl(url, options), body, {
        headers: new HttpHeaders(headers),
      });
      return response;
    } catch (e) {
      return this.errorHandler(e);
    }
  }

  errorHandler(e: any): any {
    if (e instanceof HttpErrorResponse) {
      return e.status
        ? { error: e.error, message: e.error?.message || 'network error' }
        : { error: e, message: 'network error' };
    } else {
      return { error: e, message: e.message || 'error' };
    }
  }
}
