import { NbAccessControl } from '@nebular/security';
import { MENU_ITEMS } from '../modules/layouts/menu-item';
import { User } from '../models/User';

export const ACL: NbAccessControl = {
  '1': {
    view: '*',
    create: '*',
    remove: '*',
  },
  '5': {
    view: ['Dashboard', 'ACCOUNT', 'Change Password', 'Profile', 'Logout'],
    create: '',
  },
};
