import { Injectable, Optional } from '@angular/core';
import { NbDialogConfig, NbDialogRef, NbDialogService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { DialogData, DialogResponse } from '../models/DialogData';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { DialogApproveComponent } from '../shared/dialog-approve/dialog-approve.component';
// declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  config = new NbDialogConfig({
    closeOnBackdropClick: false,
    autoFocus: true,
    hasBackdrop: true,
  });
  constructor(private dialogService: NbDialogService) {}

  open(data?: DialogData): Observable<DialogResponse> {
    // $('.cdk-overlay-container .cdk-overlay-backdrop').remove();
    // $('.cdk-overlay-container .cdk-global-overlay-wrapper').remove();
    return this.dialogService.open(DialogComponent, {
      ...this.config,
      context: {
        data: JSON.stringify(data),
      },
    }).onClose;
  }

  Approve(data?: DialogData): Observable<DialogResponse> {
    // $('.cdk-overlay-container .cdk-overlay-backdrop').remove();
    // $('.cdk-overlay-container .cdk-global-overlay-wrapper').remove();
    return this.dialogService.open(DialogApproveComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      hasBackdrop: true,
      context: {
        data: JSON.stringify(data),
      },
    }).onClose;
  }

  openRef(ref: any, data?: any): NbDialogRef<unknown> {
    return this.dialogService.open(ref, {
      ...this.config,
      context: {
        data: JSON.stringify(data),
      },
    });
  }

  openRefClass(ref: any, data?: any, dialogClass?: string): NbDialogRef<unknown> {
    const configWithWidthAndClass: NbDialogConfig = {
      ...this.config,
      context: { data: JSON.stringify(data) },
    };

    if (dialogClass) {
      configWithWidthAndClass.dialogClass = dialogClass;
    }

    return this.dialogService.open(ref, configWithWidthAndClass);
  }
}
