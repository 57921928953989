
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = await this.authService.getToken();
    if (!token.isValid()) {
      this.router.navigate(['/auth']);
    } else if (await this.authService.isTokenExpireTimeReach()) {
      const authData = this.authService.getCredential();
      if (authData) {
        const result = await this.authService.refreshToken(environment.STRATEGY_NAME, { token: authData.info.token, refresh_token: authData.info.refresh_token })
        if (result.isSuccess()) {
          const response: HttpResponse<any> = result?.getResponse();
          authData.info = {
            ...authData.info, ...{
              token: response.body.data.token,
              token_expiry: response.body.data.token_expiry,
            }
          }
          await this.authService.saveCredential(authData);
        } else {
          await this.authService.clearToken();
          this.router.navigate(['/auth']);
        }
      } else {
        await this.authService.clearToken();
        this.router.navigate(['/auth']);
      }
    }
    return token.isValid();
  }

  async canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = await this.authService.getToken();
    if (!token.isValid()) {
      this.router.navigate(['/auth']);
    } else if (await this.authService.isTokenExpireTimeReach()) {
      const authData = this.authService.getCredential();
      if (authData) {
        const result = await this.authService.refreshToken(environment.STRATEGY_NAME, { token: authData.info.token, refresh_token: authData.info.refresh_token })
        if (result.isSuccess()) {
          const response: HttpResponse<any> = result?.getResponse();
          authData.info = {
            ...authData.info, ...{
              token: response.body.data.token,
              token_expiry: response.body.data.token_expiry,
            }
          }
          await this.authService.saveCredential(authData);
        } else {
          await this.authService.clearToken();
          this.router.navigate(['/auth']);
        }
      } else {
        await this.authService.clearToken();
        this.router.navigate(['/auth']);
      }
    }
    return token.isValid();
  }
}
