<h5 id="title" class="title">Reset Password</h5>
<!-- <p class="sub-title">Enter your new password</p> -->
<form [formGroup]="resetpasswordForm" (ngSubmit)="requestPass()" aria-labelledby="title">
    <div class="form-control-group">
        <label class="label" for="input-password">Enter your password:</label>
        <nb-form-field>
            <input nbInput formControlName="password" id="input-password" name="password"
                [type]="showPassword? 'text' : 'password'" placeholder="Password" autofocus fullWidth fieldSize="large"
                [attr.aria-invalid]="resetpasswordForm.controls.password.invalid && resetpasswordForm.controls.password.touched ? true : null">
            <button type="button" nbSuffix nbButton ghost (click)="this.showPassword = !this.showPassword;">
                <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva"
                    [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                </nb-icon>
            </button>
        </nb-form-field>
        <ng-container
            *ngIf="resetpasswordForm.controls.password.invalid && resetpasswordForm.controls.password.touched">

            <p class="caption status-danger" *ngIf="resetpasswordForm.controls.password.errors?.required">
                Password is required!
            </p>
        </ng-container>
    </div>
    <div class="form-control-group">
        <label class="label" for="input-confirm_password">Retype your password:</label>
        <nb-form-field>
            <input nbInput formControlName="confirm_password" id="input-confirm_password" name="confirm_password"
                [type]="showConfPassword ? 'text' : 'password'" placeholder="Confirm Password" fullWidth
                fieldSize="large"
                [attr.aria-invalid]="resetpasswordForm.controls.confirm_password.invalid && resetpasswordForm.controls.confirm_password.touched ? true : null">

            <button type="button" nbSuffix nbButton ghost (click)="this.showConfPassword = !this.showConfPassword;">
                <nb-icon [icon]="showConfPassword ? 'eye-outline' : 'eye-off-outline'" pack="eva"
                    [attr.aria-label]="showConfPassword ? 'hide password' : 'show password'">
                </nb-icon>
            </button>
        </nb-form-field>
        <ng-container
            *ngIf="(resetpasswordForm.controls.password.value != resetpasswordForm.controls.confirm_password.value || resetpasswordForm.controls.confirm_password.invalid) && resetpasswordForm.controls.confirm_password.touched">
            <p class="caption status-danger" *ngIf="resetpasswordForm.controls.confirm_password.errors?.required">
                Password is required!
            </p>
            <p class="caption status-danger"
                *ngIf="resetpasswordForm.controls.password.value != resetpasswordForm.controls.confirm_password.value">
                Password does not match
            </p>
        </ng-container>
        <ng-container>
            <ul class="caption">
                <li class="text-muted">At least 8 characters long</li>
                <!-- <li class="text-muted">Contains at least one uppercase character</li>
                <li class="text-muted">Contains at least one lowercase character</li>
                <li class="text-muted">Contains at least one number</li>
                <li class="text-muted">Contains at least one special character</li> -->
            </ul>
        </ng-container>
    </div>
    <button nbButton fullWidth status="primary" size="large"
        [disabled]="submitted || resetpasswordForm.invalid || resetpasswordForm.controls.password.value != resetpasswordForm.controls.confirm_password.value"
        [class.btn-pulse]="submitted">
        Reset password
    </button>
</form>
<section class="sign-in-or-up" aria-label="Sign in or sign up">
    <p><a class="text-link" routerLink="../../login">Back to Log In</a></p>
</section>
