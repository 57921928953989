import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DialogData, DialogResponse } from 'src/app/models/DialogData';
import { ToastService } from 'src/app/services/toast.service';
@Component({
  selector: 'app-dialog-approve',
  templateUrl: './dialog-approve.component.html',
  styleUrls: ['./dialog-approve.component.scss'],
})
export class DialogApproveComponent implements OnInit {
  @Input() data: string = '';
  shipment: boolean = false;
  rsa_tow: boolean = false;
  rsa_other: boolean = false;
  dialogData = new DialogData({});
  selectedServices: string[] = [];
  constructor(
    protected ref: NbDialogRef<DialogApproveComponent>,
    private _toast: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    try {
      const d: DialogData = JSON.parse(this.data);

      this.dialogData = new DialogData(d);
      const carrierType = this.dialogData.data.carrier_type || [];
      this.shipment = carrierType.includes('Shipment');
      console.log(this.shipment, typeof this.shipment);
      this.rsa_tow = carrierType.includes('RSA - Tow Service');
      this.rsa_other = carrierType.includes('RSA - Other Services');
      this.updateSelectedServices();
    } catch (error) {}
    console.log(this.dialogData);
  }

  dismiss(status: boolean) {
    let result: DialogResponse = { status: status, data: { services: this.selectedServices } };
    this.ref.close(result);
  }

  shipmentChange(event: boolean) {
    console.log(event);
    if (!event && this.getSelectedCount() <= 1) {
      console.log(event);
      this._toast.error('Atleast One Permit Should Be Selected');
      setTimeout(() => {
        this.shipment = true;
      });
    } else {
      console.log('22');
      this.shipment = event;
      this.updateSelectedServices();
    }
  }

  rsaTowChange(event: boolean) {
    console.log(event);
    if (!event && this.getSelectedCount() <= 1) {
      this._toast.error('Atleast One Permit Should Be Selected');
      setTimeout(() => {
        this.rsa_tow = true;
      });
    } else {
      this.rsa_tow = event;
      this.updateSelectedServices();
    }
  }

  rsaOtherChange(event: boolean) {
    console.log(event);
    if (!event && this.getSelectedCount() <= 1) {
      this._toast.error('Atleast One Permit Should Be Selected');
      setTimeout(() => {
        this.rsa_other = true;
      });
    } else {
      this.rsa_other = event;
      this.updateSelectedServices();
    }
  }

  private updateSelectedServices() {
    this.selectedServices = [];
    if (this.shipment) this.selectedServices.push('Shipment');
    if (this.rsa_tow) this.selectedServices.push('RSA - Tow Service');
    if (this.rsa_other) this.selectedServices.push('RSA - Other Services');
  }

  private getSelectedCount(): number {
    return [this.shipment, this.rsa_tow, this.rsa_other].filter((checked) => checked).length;
  }
}
