<div [hidden]="!status">
  <div class="loader d-flex align-items-center justify-content-center flex-column">
    <div class="loader-body">
      <div class="spinner-grow mx-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow mx-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow mx-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
