import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getDeepFromObject, NB_AUTH_OPTIONS } from '@nebular/auth';
import { AuthService } from '../auth.service';
import { ToastService } from '../../services/toast.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent implements OnInit {
  strategy: string = '';
  submitted: boolean = false;
  user: any = { email: '' };
  constructor(
    protected authService: AuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected router: Router,
    private _toast: ToastService,
    private _loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.strategy = this.getConfigValue('forms.requestPassword.strategy');
  }

  async requestPass() {
    this._loader.show();
    this.submitted = true;
    const result = await this.authService.requestPassword(this.strategy, this.user);
    this.submitted = false;
    this._loader.hide();
    if (result.isSuccess()) {
      const { body }: HttpResponse<any> = result?.getResponse();
      if (body?.data?.session_id) {
        this._toast.info('Code is send to the registered email');
        this.router.navigate([`auth/otp-verification/${body?.data?.session_id}`]);
      } else this._toast.error('Something went wrong. Please try again later');
    } else {
      const { error }: HttpErrorResponse = result?.getResponse();
      this._toast.error(error.message);
    }
  }
  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
