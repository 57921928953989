import { Injectable } from '@angular/core';
import { NbRoleProvider } from '@nebular/security';
import { Observable, of as observableOf } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleProvider implements NbRoleProvider {

  constructor(private authService: AuthService) { }

  getRole(): Observable<string> {
    const user = this.authService.getUser();
    return observableOf(String(user?.role_id ? user.role_id : ''));
  }
}
