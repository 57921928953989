import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { getDeepFromObject, NB_AUTH_OPTIONS } from '@nebular/auth';
import { ToastService } from 'src/app/services/toast.service';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  strategy: string = '';
  submitted: boolean = false;
  session_id: string = '';
  showPassword: boolean = false;
  showConfPassword: boolean = false;
  resetpasswordForm = new FormGroup({
    password: new FormControl('', [
      // 1. Password Field is Required
      Validators.required,
      // 2. Password contains
      // CustomValidators.patternValidator(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/, { hasSpecialCharacters: true }),
      // 3. Has a minimum length of 6 characters
      Validators.minLength(8),
    ]),
    confirm_password: new FormControl('', [
      // 1. Password Field is Required
      Validators.required,
      // 2. Password contains
      // CustomValidators.patternValidator(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/, { hasSpecialCharacters: true }),
      // 3. Has a minimum length of 6 characters
      Validators.minLength(8),
    ]),
  });

  constructor(
    protected authService: AuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected _router: Router,
    private _route: ActivatedRoute,
    private _toast: ToastService,
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe(async params => {
      if (params['session_id']) this.session_id = params['session_id']
    });
    this.strategy = this.getConfigValue('forms.resetPassword.strategy');
  }

  async requestPass() {
    if (this.resetpasswordForm.controls.password.value === this.resetpasswordForm.controls.confirm_password.value) {
      this.submitted = true;
      const formData = { session_id: this.session_id, password: this.resetpasswordForm.controls.password.value };
      const result = await this.authService.resetPassword(this.strategy, formData);
      this.submitted = false;
      if (result.isSuccess()) {
        const { body }: HttpResponse<any> = result?.getResponse();
        this._toast.success(body?.data?.message)
        this._router.navigate([`auth/login`])
      } else {
        const { error }: HttpErrorResponse = result?.getResponse();
        this._toast.error(error.message);
        return
      }
    } else {
      this._toast.info('Password does not match');
    }
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}

















