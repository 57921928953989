<h5 id="title" class="title">Code Verification</h5>
<p class="sub-title">Enter your Code</p>

<form (ngSubmit)="otpVerification()" aria-labelledby="title">
  <ng-otp-input class="text-center" (onInputChange)="onOtpChange($event)" [config]="{length:6}"></ng-otp-input>
  <button class="mt-3" nbButton fullWidth status="primary" size="large" [disabled]="submitted" [class.btn-pulse]="submitted">
    Verify Code
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../../login">Back to Log In</a></p>
  <!-- <p><a routerLink="../register" class="text-link">Register</a></p> -->
  <p>
    <a href="javascript:void(0)" (click)="resendOTP()" class="text-link">Resend Code</a>
  </p>
</section>
