import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';
import { AuthRoutingModule } from './auth-routing.module';
import { NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbFormFieldModule
} from '@nebular/theme';
import { LoginComponent } from './login/login.component';
import { RequestPasswordComponent } from './request-password/request-password.component';
import {  OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { environment } from 'src/environments/environment';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthComponent } from './auth.component';


@NgModule({
  declarations: [AuthComponent, LoginComponent, RequestPasswordComponent, OtpVerificationComponent, ResetPasswordComponent],
  imports: [
    NgOtpInputModule,
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    RouterModule,
    NbLayoutModule,
    NbCardModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NbAuthModule,
    NbIconModule,
    ReactiveFormsModule,
    NbFormFieldModule
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        ...NbAuthModule.forRoot({
          strategies: [
            NbPasswordAuthStrategy.setup({
              name: environment.STRATEGY_NAME,
              baseEndpoint: environment.API_ENDPOINT,
              login: {
                endpoint: '/auth/local',
                redirect: {
                  success: '/',
                  failure: null, // stay on the same page,
                },
              },
              requestPass: {
                endpoint: '/auth/password/forgot',
                method: 'post',
                redirect: {
                  success: '/',
                  failure: null,
                },
                defaultErrors: ['Enter a valid email.'],
                defaultMessages: ['Reset password instructions have been sent to your email.'],
              },
              resetPass: {
                endpoint: '/auth/password/reset',
                method: 'post',
                redirect: {
                  success: '/',
                  failure: null,
                },
                defaultErrors: ['Something went wrong, please try again.'],
                defaultMessages: ['Your password has been changed successfully'],
              },
              refreshToken: {
                endpoint: '/auth/token',
                method: 'post',
                redirect: {
                  success: '/',
                  failure: null,
                },
                defaultErrors: ['Something went wrong, please try again.'],
                defaultMessages: ['New token created successfully'],
              },
              logout: {
                endpoint: '/auth/logout',
                method: 'post',
                redirect: {
                  success: '/',
                  failure: null,
                },
                defaultErrors: ['Something went wrong, please try again.'],
                defaultMessages: ['You have been logged out successfully.'],
              }
            }),
          ],
          forms: {
            login: {
              redirectDelay: 1000, // delay before redirect after a successful login, while success message is shown to the user
              strategy: environment.STRATEGY_NAME,  // provider id key. If you have multiple strategies, or what to use your own
              rememberMe: true,   // whether to show or not the `rememberMe` checkbox
              showMessages: {     // show/not show success/error messages
                success: true,
                error: true,
              },
            },
            requestPassword: {
              strategy: environment.STRATEGY_NAME,
            },
            resetPassword: {
              strategy: environment.STRATEGY_NAME,
            }
          },
        })?.providers || [],
      ],
    };
  }
}
