import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NbLogoutComponent } from '@nebular/auth';
import { LoginComponent } from './login/login.component';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', data: { title: 'Login' }, component: LoginComponent },
  { path: 'logout', data: { title: 'Logout' }, component: NbLogoutComponent },
  { path: 'request-password', data: { title: 'Forgot Password' }, component: RequestPasswordComponent },
  { path: 'otp-verification/:session_id', data: { title: 'Code Verification' }, component: OtpVerificationComponent },
  { path: 'reset-password/:session_id', data: { title: 'Reset Password' }, component: ResetPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
