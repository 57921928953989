export function copy(obj: any, targetObj: any) {
  targetObj = targetObj || {}
  for (let i in obj) {
    targetObj[i] = obj[i]
  }
  return targetObj
}

export function deepCopy(obj: object): object {
  return JSON.parse(JSON.stringify(obj)) || {};
}

export function deepCopyAny(obj: any): any {
  return JSON.parse(JSON.stringify(obj)) || {};
}

export function isDefined(variable: any) {
  return typeof variable !== 'undefined'
}

export function isNull(value: any) {
  return value === null;
}

export function isString(value: any) {
  return typeof value === 'string';
}

export function isNumber(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isArray(n: any) {
  return Array.isArray(n)
}

export function isEmail(email: any) {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isObject(val: any) {
  if (val === null) {
    return false;
  }
  return ((typeof val === 'function') || (typeof val === 'object'));
}

export function isDate(dt: any) {
  return (null !== dt) && !isNaN(dt) && ("undefined" !== typeof dt.getDate);
}

export function makeUID(l: number) {
  var text = "";
  var char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < l; i++) {
    text += char_list.charAt(Math.floor(Math.random() * char_list.length));
  }
  return text;
}

export function uniqueNumbersArray(a: Array<number>) {
  return a.filter((item, i, ar) => ar.indexOf(item) === i);
}

export function toEntityString(str: string) {
  return str.replace(/[^a-zA-Z0-9]/g,'_').toLowerCase();
}

export function isEmptyObject(value: object) {
  return Object.keys(value).length === 0;
}
