<img class="img-fluid w-100 logo-login my-2" src="assets\images\town_connect.png"  />
<p class="text-center f-22 m-0 py-5 my-1"><span class="orange-text">Hello!</span> Log in with your email.</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li >{{ errors }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>success!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title" class="pt-3">

  <div class="form-control-group mb-4">
    <label class="label" for="input-email">Email address</label>
    <input nbInput fullWidth [(ngModel)]="auth.username" #email="ngModel" name="email" id="input-email"
      pattern=".+@.+\..+" placeholder="Email address" fieldSize="large" autofocus
      [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null">
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">
        Email is required!
      </p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        Email should be the real one!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group mb-4">
    <span class="label-with-link">
      <label class="label" for="input-password">Password</label>
      <!-- <a class="forgot-password caption-2" routerLink="/auth/request-password">Forgot Password?</a> -->
    </span>
    <input nbInput fullWidth [(ngModel)]="auth.password" #password="ngModel" name="password" type="password"
      id="input-password" placeholder="Password" fieldSize="large"
      [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.password.required')"
      [minlength]="getConfigValue('forms.validation.password.minLength')"
      [maxlength]="getConfigValue('forms.validation.password.maxLength')"
      [attr.aria-invalid]="password.invalid && password.touched ? true : null">
      <span class="label-with-link">
        <a class="forgot-password caption-2 text-right w-100 d-block mt-3" style="color: orange ;" routerLink="/auth/request-password">Forgot Password?</a>
      </span>
    <ng-container *ngIf="password.invalid && password.touched ">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Password is required!
      </p>
      <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contain
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p>
    </ng-container>
  </div>

  <div class="form-control-group my-3 accept-group">
    <nb-checkbox [(ngModel)]="auth.rememberMe"><span class="to-checkbox">Remember me</span></nb-checkbox>
  </div>

  <button size="large" [disabled]="submitted || !form.valid"
  [class.btn-pulse]="submitted" class="btn btn-primary btn-lg w-100">Login</button>

</form>
