import { Inject, Injectable } from '@angular/core';
import { NbAuthResult, NbAuthStrategy, NbAuthToken, NbTokenService, NB_AUTH_STRATEGIES } from '@nebular/auth';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AES, SHA256, enc } from 'crypto-js';
import { Auth } from 'src/app/models/Auth';
import { User } from '../models/User';
import { HttpResponse } from '@angular/common/http';
import { deepCopy } from '../helpers/util.function';
import { Moment } from 'moment-timezone';
import { moment as m, timeRemaining } from 'src/app/helpers/helpers.function';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User | any;
  token: string = '';
  status = false;
  token_expiry!: Moment;
  private userInfo: BehaviorSubject<User | null>;
  public badgeCount: BehaviorSubject<any>;
  public IncbadgeCount: BehaviorSubject<boolean>;
  public sideMenu: BehaviorSubject<boolean>;
  constructor(
    protected tokenService: NbTokenService,
    @Inject(NB_AUTH_STRATEGIES) protected strategies: any
  ) {
    if (!this.token_expiry) this.getCredential();
    this.userInfo = new BehaviorSubject<User | null>(null);
    this.badgeCount = new BehaviorSubject<any>(false);
    this.IncbadgeCount = new BehaviorSubject<any>(false);
    this.sideMenu = new BehaviorSubject<any>(false);
  }

  /**
   * Retrieves current authenticated token stored
   * @returns {Observable<any>}
   */
  getToken(): Promise<NbAuthToken> {
    return this.tokenService.get().toPromise();
  }

  /**
   * Returns true if auth token is present in the token storage
   * @returns {Observable<boolean>}
   */
  async isAuthenticated(): Promise<boolean> {
    const token = await this.getToken();
    return token.isValid();
  }

  /**
   * Returns true if valid auth token is present in the token storage.
   * If not, calls the strategy refreshToken, and returns isAuthenticated() if success, false otherwise
   * @returns {Observable<boolean>}
   */
  async isAuthenticatedOrRefresh(): Promise<boolean> {
    const token = await this.getToken();
    if (token.getValue() && !token.isValid()) {
      const res = await this.refreshToken(token.getOwnerStrategyName(), token);
      if (res.isSuccess()) {
        const response: HttpResponse<any> = res?.getResponse();
        let c = this.getCredential();
        if (c) {
          c.info = {
            token: response.body.data.token,
          }
          await this.saveCredential(c);
        }
        return this.isAuthenticated();
      } else {
        return false;
      }
    } else {
      return token.isValid();
    }
  }


  /**
   * Authenticates with the selected strategy
   * Stores received token in the token storage
   *
   * Example:
   * authenticate('email', {email: 'email@example.com', password: 'test'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  authenticate(strategyName: string = environment.STRATEGY_NAME, data?: any): Promise<NbAuthResult> {
    return this.getStrategy(strategyName).authenticate(data)
      .pipe(
        switchMap((result: NbAuthResult) => {
          return this.processResultToken(result);
        }),
      ).toPromise();
  }

  /**
   * Authenticates with the selected strategy
   * Stores received token in the token storage
   *
   * Example:
   * authenticate('email', {email: 'email@example.com', password: 'test'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  reAuthenticate(strategyName: string = environment.STRATEGY_NAME, data?: any): Promise<NbAuthResult> {
    return this.getStrategy(strategyName).authenticate(data)
      .pipe(
        switchMap((result: NbAuthResult) => {
          return this.processResultToken(result);
        }),
      ).toPromise();
  }


  /**
   * Sends forgot password request to the selected strategy
   *
   * Example:
   * requestPassword('email', {email: 'email@example.com'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  requestPassword(strategyName: string = environment.STRATEGY_NAME, data?: any): Promise<NbAuthResult> {
    return this.getStrategy(strategyName).requestPassword(data).toPromise();
  }

  /**
   * Tries to reset password with the selected strategy
   *
   * Example:
   * resetPassword('email', {newPassword: 'test'})
   *
   * @param strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  resetPassword(strategyName: string = environment.STRATEGY_NAME, data?: any): Promise<NbAuthResult> {
    return this.getStrategy(strategyName).resetPassword(data).toPromise();
  }

  /**
   * Sends a refresh token request
   * Stores received token in the token storage
   *
   * Example:
   * refreshToken('email', {token: token})
   *
   * @param {string} strategyName
   * @param data
   * @returns {Observable<NbAuthResult>}
   */
  async refreshToken(strategyName: string = environment.STRATEGY_NAME, data?: any): Promise<NbAuthResult> {
    const rToken = await this.getStrategy(strategyName).refreshToken(data).toPromise();
    return this.processResultToken(rToken);
  }

  /**
     * Sign outs with the selected strategy
     * Removes token from the token storage
     *
     * Example:
     * logout('email')
     *
     * @param strategyName
     * @returns {Observable<NbAuthResult>}
     */
  async logout(strategyName: string = environment.STRATEGY_NAME): Promise<NbAuthResult> {
    return this.getStrategy(strategyName).logout().toPromise();
  }

  /**
   * Get registered strategy by name
   *
   * Example:
   * getStrategy('email')
   *
   * @param {string} provider
   * @returns {NbAbstractAuthProvider}
   */
  protected getStrategy(strategyName: string = environment.STRATEGY_NAME): NbAuthStrategy {
    const found = this.strategies.find((strategy: NbAuthStrategy) => strategy.getName() === strategyName);
    if (!found) throw new TypeError(`There is no Auth Strategy registered under '${strategyName}' name`);
    return found;
  }

  private processResultToken(result: NbAuthResult): Promise<NbAuthResult> {
    if (result.isSuccess() && result.getToken()) {
      this.tokenService.set(result.getToken());
    }
    return new Promise((resolve, reject) => { resolve(result) });
  }

  hash(str: string): string {
    return SHA256(str).toString();
  }

  encrypt(str: string): string {
    return AES.encrypt(str, environment.HASH_KEY).toString();
  }

  decrypt(str: string): string {
    const valueBytes = AES.decrypt(str, environment.HASH_KEY);
    return valueBytes.toString(enc.Utf8);
  }

  async saveCredential(auth: Auth): Promise<void> {
    this.user = auth.info?.user;
    this.status = true;
    this.token = auth.info?.token;
    this.token_expiry = m(auth?.info?.token_expiry);
    const sessionKey = this.hash(`${environment.APP_NAME}_auth`);
    const sessionValue = this.encrypt(JSON.stringify(auth));
    localStorage.setItem(sessionKey, sessionValue);
  }

  getCredential(): Auth | false {
    try {
      const sessionKey = this.hash(`${environment.APP_NAME}_auth`);
      const sessionValue = localStorage.getItem(sessionKey);
      // console.log(sessionValue,'auth')
      if (!!sessionValue) {
        const auth: Auth = JSON.parse(this.decrypt(sessionValue));
        // console.log(auth,'auth')
        this.user = auth?.info?.user;
        this.status = true;
        this.token = auth?.info?.token;
        this.token_expiry = m(auth?.info?.token_expiry);
        return auth;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async setSession(): Promise<boolean> {
    const authData = this.getCredential();
    if (!authData) {
      return false;
    }
    // console.log("Hi");
    const result = await this.authenticate(environment.STRATEGY_NAME, deepCopy(authData));
    if (result.isSuccess()) {
      const response: HttpResponse<any> = result?.getResponse();
      authData.info = { ...authData.info, ...{ token: response.body.data.token, user: response.body.data.user, refresh_token: response.body.data.refresh_token, token_expiry: response.body.data.token_expiry } };
      await this.saveCredential(authData);
    }
    return true;
  }

  clearCredential(): void {
    const sessionKey = this.hash(`${environment.APP_NAME}_auth`);
    localStorage.removeItem(sessionKey);
  }

  getUser(): User {
    if (this.user) return this.user;
    try {
      const sessionKey = this.hash(`${environment.APP_NAME}_auth`);
      const sessionValue = localStorage.getItem(sessionKey);
      if (!!sessionValue) {
        const auth: Auth = JSON.parse(this.decrypt(sessionValue));
        this.user = auth.info?.user;
        this.status = true;
        this.token = auth.info?.token;
        this.token_expiry = m(auth?.info?.token_expiry);
        return this.user;
      }
      return this.user;
    } catch (error) {
      return this.user;
    }
  }

  clearToken(): Promise<null> {
    return this.tokenService.clear().toPromise();
  }

  getUserInfo(): Observable<any> {
    return this.userInfo.asObservable();
  }

  setUserInfo(user: User): void {
    this.userInfo.next(user);
  }

  getBadgeCount(): Observable<any> {
    return this.badgeCount.asObservable();
  }
  setBehaviourCount(data:any): void {
    this.badgeCount.next(data);
  }

  getIncBadgeCount(): Observable<any> {
    return this.IncbadgeCount.asObservable();
  }
  setIncBehaviourCount(data:any): void {
    this.IncbadgeCount.next(data);
  }

  async isTokenExpireTimeReach(): Promise<boolean> {
    // console.log(this.token_expiry)
    if (!this.token_expiry) {
      let cred = this.getCredential();
      if(!cred) return true;

    }
    return timeRemaining(this.token_expiry, 'seconds') < environment.TOKEN_EXPIRE_IN;
  }

  getSideMenu(): Observable<any> {
    return this.sideMenu.asObservable();
  }

  setSideMenu(data:any): void {
    this.sideMenu.next(data);
  }
}
